.item-producto:focus .product-wrap, 
.item-producto:hover .product-wrap{
    background-color: #00a3d4!important;
    justify-content: end;
    height: 100%;
    flex-direction: column-reverse;
}

.product-wrap {
    min-height: 338px;
}

.list-products .item-descarga:focus, 
.list-products .item-descarga:hover{
     border: 1px solid #00a3d4!important;
     position: relative;
}

.item-producto:focus .product-wrap h3,
.item-producto:hover .product-wrap h3{
    color: #fff;
    z-index: 12;
    font-weight: 400;
    position: absolute;
    top: 23%;
    width: 90%;
}

.item-producto:focus span.type-doc,
.item-producto:focus span.file-info,
.item-producto:hover span.type-doc,
.item-producto:hover span.file-info {
    z-index: 15;
}

.item-producto:focus span.type-doc a,
.item-producto:focus span.file-info a,
.item-producto:hover span.type-doc a,
.item-producto:hover span.file-info a{
    color: #fff!important;
}

.item-descarga:focus .document-info, 
.item-descarga:hover .document-info {
    opacity: 1!important;
    transition: none!important;
}

.item-descarga:focus .image-wrap, 
.item-descarga:hover .image-wrap {
    display: none!important;
}

.comment-form .item-producto .secondary input[type=submit], 
.item-producto .secondary .btn, 
.item-producto .secondary .comment-form input[type=submit], 
.item-producto .secondary .search-form .search-submit, 
.search-form .item-producto .secondary .search-submit {
    padding: 16px 30px;
    margin: 0 0px;
}

.item-descarga .btn-primary, 
.btn-primary.disabled, 
.item-descarga .btn-primary:disabled {
    background-color: #fff;
    border-color: #fff;
    cursor: pointer;
    color: #00a3d4;
    margin-bottom: 10px!important;
    border-top: none
}

.item-producto.item-descarga:focus .btn.btn-primary, 
.item-producto.item-descarga:hover .btn.btn-primary{
    background-color: #fff;
    border-color: #fff;
    color: #00a3d4;
    margin-bottom: 10px;
}

.postventa .asistencia__description p {
    line-height: 2rem;
    font-size: 1rem !important;
    font-weight: 400 !important;
    text-align: left !important;
    text-transform: none !important;
}
.postventa .asistencia__description ul {
    width: 90% !important;
}
